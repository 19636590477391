import React from 'react'
import './Terms.css'

const Terms = () => {
    return (
        <div className="container mt-5">
        <h1 className="text-center mb-4">Termos e Condições de Uso do Aplicativo “ALIVIUS”</h1>

        <p className="lead">Leia atentamente os termos e condições de uso (“<span style={{textDecoration: 'underline'}}>Termos e Condições</span>”) antes de usar o aplicativo ALIVIUS (“<span style={{textDecoration: 'underline'}}>Aplicativo</span>”). Estes Termos e Condições estabelecem as condições legais e vinculantes que você, na qualidade de Usuário (conforme definido abaixo) do Aplicativo, compromete-se a cumprir de forma livre e voluntária.</p>

        <p>A Política de Processamento de Informações (“<span style={{textDecoration: 'underline'}}>PPI</span>”) da Operadora da Plataforma (conforme definido abaixo) é parte integrante destes Termos e Condições. Portanto, sempre que for feita referência neste texto aos Termos e Condições, também será feita referência à PPI, que pode ser acessado neste link: <a href="www.myalivius.com/PrivacyPolicy.html">www.myalivius.com/PrivacyPolicy.html</a>.</p>

        <p>Os termos em maiúsculas usados neste documento e não definidos de outra forma terão o significado estabelecido entre aspas.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>1. Administração do Aplicativo</h2>
        <p>O acesso ao Aplicativo é oferecido ao Usuário a seu pedido  para facilitar o relacionamento entre o Usuário e o &lt; nome do hospital &gt;</p>
        <p>Este Aplicativo é administrado pelo &lt; nome do hospital &gt;, uma empresa registrada e existente de acordo com as leis do Brasil, no endereço registrado em &lt; endereço registrado &gt; com experiência e autorizada para a prestação de serviços médicos, de saúde e relacionados, que incluem, sem limitação, atendimento hospitalar e ambulatorial, diagnóstico, serviços terapêuticos, serviços laboratoriais, medicamentos, cuidados de enfermagem oncológica, entre outros (“<span style={{textDecoration: 'underline'}}>Administradora</span>”).</p>
        <p style={{textDecoration: 'underline'}}>A Administradora assumirá toda a responsabilidade por todos os Conteúdos (conforme definido abaixo) e as informações transportadas e incorporadas nas e através das Funções (conforme definido abaixo).</p>
        <h2 className="mt-4" style={{textDecoration: 'underline'}}>2. Usuários</h2>
        <p>Para os fins destes Termos e Condições, “Usuário” refere-se, em geral, a qualquer pessoa que use o Aplicativo e cobre os seguintes tipos de Usuários específicos:</p>
        <ul className="list-group">
            <li className="list-group-item">2.1<span style={{textDecoration: 'underline'}}>“Prestador de Cuidados”</span>: ão os trabalhadores autorizados, contratados, subcontratados e agentes com ou sem representação, afiliados à Administradora, e que prestam cuidados ou Assistência (conforme definido abaixo) ao Paciente.</li>
            <li className="list-group-item">2.2.<span style={{textDecoration: 'underline'}}>“Paciente”</span>: A pessoa que atualmente recebe consulta, serviços médicos hospitalares e clínicos ou terapia intensiva, diretamente da Administradora.</li>
            <li className="list-group-item">2.3.<span style={{textDecoration: 'underline'}}>“Cuidador”</span>: Uma pessoa diferente do Prestador de Cuidados, que o Paciente designou para fornecer assistência e suporte no uso do Aplicativo. A menos que o contexto exija de outra forma, quando for feita referência ao Paciente nestes Termos e Condições, deve ser entendido que também inclui o Cuidador.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>3. Descrição do Aplicativo</h2>
        <p>O Aplicativo consiste em uma solução de software com base na Internet que pode ser acessada por meio de um aplicativo (app) para dispositivos móveis e/ou por portal da web, que fornece ao Usuário as Funções (conforme definido abaixo), a fim de apoiar os Usuários com os aspectos emocionais e físicos do câncer.</p>
        <p style={{textDecoration: 'underline'}}>O Aplicativo não procura prestar serviços que envolvam direta ou indiretamente qualquer atividade que implique a prestação de serviços de saúde ou atividades que sejam restritas ou sujeitas a regulamentos, incluindo, sem limitação, diagnóstico, prescrição para o uso de medicamentos ou farmacovigilância, telemedicina ou tele-orientação. No entanto, nada neste documento deve ser entendido como um impedimento para que a Administradora e seus Prestadores de Cuidados autorizados cumpram suas obrigações legais de informar às autoridades competentes sobre eventos adversos eventualmente comunicados pelos Usuários por meio da Plataforma, especialmente, sem limitação, àqueles decorrentes da Lei Federal 13.021/2014, da Resolução RDC nº 406/2020 e da RDC nº 36/2013.</p>
        <p>As funções do Aplicativo são principalmente as seguintes (“Funções”):</p>
        <ul className="list-group">
            <li className="list-group-item">a. Troca de informações para prestar Assistência: O Aplicativo permite a interação entre diferentes Usuários para apoiar o Paciente com os aspectos emocionais e físicos do câncer (“<span style={{textDecoration: 'underline'}}>Assistência</span>”), por meio de materiais informativos produzidos e selecionados pela Administradora ou terceiros (“<span style={{textDecoration: 'underline'}}>Conteúdo</span>”), que consistem em sugestões de dieta criadas pela Administradora, conteúdo educacional e mensagens por meio de um sistema de mensagens ou bate-papo, tais como, mas não se limitando a WhatsApp ou outros alplicativos similares.</li>
            <li className="list-group-item">b. Ferramentas de assistência: O Aplicativo fornece (i) alertas e notificações que lembram o Paciente sobre aspectos de seu tratamento, como monitoramento de planos de nutrição, cronograma de medicação e consultas médicas, e (ii) um batepapo para interagir entre os Usuários para monitorar os aspectos do tratamento do Paciente e cuidar de seu estado emocional e físico.</li>
            <li className="list-group-item">c. Carregamento de informações: O Aplicativo permite que as informações do Paciente sejam carregadas para melhorar a prestação de Assistência. Por exemplo, os Pacientes podem relatar seu humor, fazer anotações para futuras consultas médicas, compartilhar os efeitos colaterais do tratamento, carregar documentos digitalizados para compartilhar com os  Prestadores de Cuidados, registrar sua ingestão de alimentos etc.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>4. Requisitos de Idade</h2>
        <ul className="list-group">
            <li className="list-group-item">4.1. Para usar o Aplicativo, o Usuário deve ter 18 anos de idade ou a idade necessária para ser considerado maior de idade.</li>
            <li className="list-group-item">4.2. Após a aceitação destes Termos e Condições, o Usuário afirma, declara e garante que tem 18 anos de idade e/ou é maior de idade de acordo com as leis de sua jurisdição, e possui a capacidade de assumir e celebrar contratos legalmente, e que todas as informações enviadas em seu registro para o Aplicativo são verdadeiras. A Administradora reserva-se o direito de solicitar provas para comprovar a idade dos Usuários. A Administradora pode suspender, a seu critério e a qualquer momento, as contas até que seja fornecida prova suficiente de idade.</li>
            <li className="list-group-item">4.3. A Administradora pode, a seu critério e a qualquer momento, recusar-se a oferecer acesso ao Aplicativo a qualquer pessoa e alterar os critérios de admissão a ele.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>5. Registro</h2>
        <ul className="list-group">
            <li className="list-group-item">5.1. O Usuário deve se registrar no Aplicativo para poder usar as Funções. Após o registro, o nome de usuário do (i) Prestador de Cuidados, será seu ID de e-mail, (ii) Paciente, será seu ID de Paciente registrado no Sistema EHR, e (iii) Cuidador, será seu número de celular. O Usuário deve fornecer as informações exigidas pelo Aplicativo, que serão atribuídas ao perfil do Usuário.</li>
            <li className="list-group-item">5.2. O Usuário deve fornecer informações verdadeiras, exatas, completas e atualizadas para o cadastro. Não fazê-lo constituirá uma violação dos Termos e Condições, o que pode resultar no cancelamento ou suspensão imediata da sua conta.</li>
            <li className="list-group-item">5.3. A Administradora será responsável por obter a autorização prévia, expressa e informada do Prestador de Cuidados e do Paciente para seu registro de acordo com as disposições das leis aplicáveis.</li>
            <li className="list-group-item">5.4. A Administradora reserva-se o direito, a seu exclusivo critério, de recusar o registro ou cancelar a conta de um Usuário em caso de violação destes Termos e Condições.</li>
            <li className="list-group-item">5.5. O Usuário é o único responsável pela atividade realizada em sua conta e será responsável por manter sua senha segura e confidencial. Como tal, todas as atividades que o Usuário realiza no ou através do Aplicativo são de inteira responsabilidade do Usuário e, como resultado, o Usuário se compromete a manter a Administradora livre de danos por quaisquer danos que resultem do uso do Aplicativo.</li>
            <li className="list-group-item">5.6. O Usuário notificará imediatamente a Administradora por escrito sobre qualquer uso não autorizado da conta do Usuário ou qualquer outra violação de segurança relacionada a ela, para a adoção das medidas necessárias.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>6. Contrato de Uso do Aplicativo</h2>
        <p>6.1 Através do seu registo no Aplicativo, o Usuário afirma, declara e garante que aceita e concorda com os presentes Termos e Condições, com todas as regras, políticas e procedimentos operacionais que são publicados no Aplicativo ou que são publicados após a aceitação dos Termos e Condições.</p>
        <p>6.2 O acesso às Funções está sujeito à aceitação dos Termos e Condições. A Administradora reserva-se o direito de modificar, adicionar ou substituir os presentes Termos e Condições, a qualquer momento, e sem aviso prévio, através da publicação dos termos e condições que foram modificados, substituídos ou adicionados, dentro do Aplicativo. Essas modificações também serão relatadas por meio de notificações do usuário.</p>
        <p>6.3 Se o Usuário não concordar com estes Termos e Condições, ele não deve fazer login ou usar o Aplicativo.</p>
        <p>6.4 O uso de Funções é limitado principalmente ao país em que a conta foi criada. O Conteúdo disponível no Aplicativo pode ser limitado e variar dependendo da localização.</p>
        <p>6.5 O Usuário será o único responsável pelo acesso à Internet necessário para o correto funcionamento do Aplicativo. Todas as cobranças de acesso à Internet serão por conta do Usuário.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>7. Alterações ao Aplicativo e aos Termos e Condições</h2>
        <ul className="list-group">
            <li className="list-group-item">7.1. O uso do Aplicativo após a publicação das alterações feitas nos Termos e Condições constitui aceitação das referidas alterações e da totalidade dos Termos e Condições.</li>
            <li className="list-group-item">7.2. A Administradora reserva-se o direito de suspender e restringir o acesso ao aplicativo. Fica expressamente excluída qualquer tipo de responsabilidade que possa ser atribuída à Administradora em decorrência do exposto, seja permanente ou temporária, que o Aplicativo venha a sofrer.</li>
            <li className="list-group-item">7.3. Os Usuários são responsáveis por consultar periodicamente os Termos e Condições, bem como os demais documentos publicados no Aplicativo, a fim de se manterem informados sobre possíveis modificações, acréscimos ou substituições a ele.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>8. Obrigações Gerais do Usuário</h2>
        <ul className="list-group">
            <li className="list-group-item">8.1. O Usuário compromete-se a não usar o Aplicativo para fins que sejam contra os Termos e Condições, as leis da República da Colômbia e/ou as leis da jurisdição que se aplicam a eles.</li>
            <li className="list-group-item">8.2. Os usuários que fazem uso do Aplicativo, incluindo todas as características e Funções relacionadas a ele, concordam em não arquivar, baixar (exceto o cache necessário para uso pessoal), reproduzir, distribuir, modificar, comunicar, mostrar, exibir, executar, copiar, duplicar, publicar, conceder licenças, criar trabalhos derivados com base no Aplicativo, oferecer em venda ou usar o Conteúdo e/ou informações contidas ou obtidas do Aplicativo.</li>
            <li className="list-group-item">8.3. O Usuário compromete-se a não usar qualquer <span style={{fontStyle: 'italic'}}>spider, scraper</span> ou outra forma automatizada para acessar o Aplicativo e a não decompilar, realizar engenharia inversa, decodificar, decompilar, desmontar ou tentar derivar o código-fonte ou ideias ou algoritmos subjacentes de qualquer parte do Aplicativo ou de qualquer uma de suas Funções.</li>
            <li className="list-group-item">8.4. O Usuário compromete-se a não carregar, publicar, enviar por e-mail ou comunicar de qualquer outra forma qualquer material concebido, interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou dispositivo para o funcionamento do Aplicativo.</li>
            <li className="list-group-item">8.5. É proibida a modificação, reprodução, publicação ou transferência de informações a terceiros que não estejam expressamente autorizadas nestes Termos e Condições. A Administradora reserva-se o direito, a seu exclusivo critério, de recusar ou cancelar o acesso ao Aplicativo se forem detectadas condutas ou atividades que, a critério exclusivo da Administradora, atuem contra estes Termos e Condições.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>9. Obrigações Específicas do Usuário</h2>
        <p>Ao aceitar estes Termos e Condições, o Usuário concorda, diretamente ou por meio de um intermediário, com o seguinte, em relação ao Aplicativo:</p>
        <ul className="list-group">
            <li className="list-group-item">9.1. Não usar o Aplicativo para obter a prestação de serviços de saúde ou atividades restritas ou sujeitas a regulamentação. Isso inclui, sem limitação, diagnóstico, prescrição para uso de drogas ou farmacovigilância, telemedicina e tratamento de tele-orientação.</li>
            <li className="list-group-item">9.2. Não violar direitos de propriedade intelectual.</li>
            <li className="list-group-item">9.3. Não realizar atividades que configurem práticas anticompetitivas, concorrência desleal ou que violem direitos de defesa do consumidor.</li>
            <li className="list-group-item">9.4. Não promover informações imprecisas ou enganosas.</li>
            <li className="list-group-item">9.5. Não realizar atividades que constituam crime, especialmente relacionadas ao uso de tecnologia e informações e à substituição de terceiros ou fraude.</li>
            <li className="list-group-item">9.6. Não realizar atividades que constituam ações obscenas, ofensivas ou a invasão da privacidade de terceiros.</li>
            <li className="list-group-item">9.7. Não incorrer em calúnia, abuso, assédio, ameaças ou violações de qualquer liberdade pública ou direito civil ou humano ou qualquer outro direito de propriedade, privacidade e/ou publicidade de outros Usuários ou terceiros.</li>
            <li className="list-group-item">9.8. Não violar os regulamentos de proteção de dados pessoais.</li>
            <li className="list-group-item">9.9. Não impor encargos irracionais ou desproporcionais à Administradora e/ou à infraestrutura do Aplicativo. O critério para definir o que é considerado um ônus irracional ou desproporcional fica inteiramente a critério da Administradora ou do Operador da Plataforma (conforme definido abaixo).</li>
            <li className="list-group-item">9.10. Não interferir ou tentar interferir no funcionamento correto do Aplicativo.</li>
            <li className="list-group-item">9.11. Não duplicar, distribuir, enviar ou transferir de qualquer forma qualquer uma das Funções às quais o Usuário possa ter acesso.</li>
            <li className="list-group-item">9.12. Não usar este Aplicativo como meio para realizar atividades ilegais ou não autorizadas, tanto na Colômbia quanto em qualquer outro país.</li>
            <li className="list-group-item">9.13. Denunciar qualquer violação dos Termos e Condições por outros Usuários ou terceiros de que tenham conhecimento. Para tanto, enviarão e-mail para o endereço eletrônico support@myalivius.com , para que Operadora da Plataforma tome as providências que julgar cabíveis.</li>
            <li className="list-group-item">9.14. Não carregar programas de computador ou outro material protegido por leis de propriedade intelectual para o Aplicativo, a menos que autorizado pelo proprietário dos referidos direitos.</li>
            <li className="list-group-item">9.15. Não carregar para o Aplicativo arquivos que contenham vírus ou qualquer outro arquivo ou programa de computação que possa afetar a programação do Aplicativo.</li>
            <li className="list-group-item">9.16. Não armazenar qualquer tipo de anúncio no Aplicativo, tendo em vista que as Funções não podem ser utilizadas para fins comerciais diferentes daqueles aqui declarados.</li>
            <li className="list-group-item">9.17. Não compartilhar a senha e protegê-la para evitar o acesso ao perfil de usuário no Aplicativo por pessoas que não sejam o Usuário.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>10. Obrigações da Administradora</h2>
        <ul className="list-group">
            <li className="list-group-item">10.1. Manter o Aplicativo disponível de acordo com os termos acordados nestes Termos e Condições.</li>
            <li className="list-group-item">10.2. Se considerar necessário, prestar suporte e manutenção do Aplicativo, o que pode fazer por conta própria ou por meio de terceiros.</li>
            <li className="list-group-item">10.3. Cumprir os regulamentos de proteção de dados aplicáveis contidos na Lei Federal nº 13.709/2018 na Política de Privacidade do Aplicativo, bem como nos regulamentos que a modificam, adicionam ou revogam e na PPI.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>11. Declarações e Garantias do Usuário</h2>
        <p>Ao aceitar estes Termos e Condições, os Usuários afirmam, declaram e garantem que entendem, aceitam e reconhecem que:</p>
        <ul className="list-group">
            <li className="list-group-item">11.1. O Aplicativo não se destina a fins de pesquisa científica e não será usado para medir a eficácia ou segurança de qualquer produto farmacêutico ou tecnologia de saúde.</li>
            <li className="list-group-item">11.2. O Aplicativo pode fornecer opiniões de especialistas consultados em um campo específico relacionado ao Conteúdo. Tais informações representam somente as opiniões dos respectivos peritos que não são necessariamente as da Administradora.</li>
            <li className="list-group-item">11.3. A Administradora não garante que o Conteúdo e as informações disponíveis no Aplicativo estejam livres de erros ou imprecisões. Da mesma forma, os Usuários declaram que entendem e estão satisfeitos com o Aplicativo e suas Funções. Portanto, o Usuário não pode invocar a reparação de defeitos ocultos ou instituições legais semelhantes durante a execução dos Termos e Condições ou após isso.</li>
            <li className="list-group-item">11.4. A Administradora não garante a disponibilidade, infalibilidade e continuidade do funcionamento do Aplicativo nem de suas Funções. Quando aplicável, a Administradora pode avisar sobre interrupções na operação do Aplicativo e/ou das Funções. Caso o funcionamento do Aplicativo e/ou das Funcionalidades seja interrompido, a Administradora fica isenta de qualquer responsabilidade por danos ou prejuízos de qualquer natureza.</li>
            <li className="list-group-item">11.5. A Administradora não garante que os Conteúdos e informações disponíveis no Aplicativo sejam suficientes e/ou úteis para o Usuário. Da mesma forma, os Conteúdos e Funções não constituem a prestação de serviços médicos, de saúde e afins de acordo com os regulamentos aplicáveis.</li>
            <li className="list-group-item">11.6. A Administradora tem o direito de rejeitar a entrada de qualquer pessoa, a qualquer momento e por qualquer motivo.</li>
            <li className="list-group-item">11.7. A Administradora e o Operador da Plataforma (conforme definido abaixo) têm o direito de usar os Dados Pessoais (conforme definido abaixo) a seu próprio critério, de acordo com os Termos e Condições e a PPI.</li>
            <li className="list-group-item">11.8. O Operador da Plataforma não é responsável pelas Funções e Conteúdos nos Aplicativos.</li>
            <li className="list-group-item">11.9. A Administradora tem o direito de usar os Conteúdos fornecidos pelos Usuários de acordo com as disposições dos Termos e Condições.</li>
            <li className="list-group-item">11.10. A Administradora pode conceder acesso ao Operador da Plataforma ou a outros terceiros, permitindo que eles, se necessário, forneçam suporte e manutenção no Aplicativo.</li>
            <li className="list-group-item">11.11. A Administradora não garante e, portanto, não é responsável pela presença de vírus ou outros elementos nocivos nos Conteúdos e no Aplicativo ou que possam surgir como resultado do login no Aplicativo, o que pode produzir alterações nos sistemas de computador e/ou dispositivos dos Usuários ou de terceiros ou arquivos armazenados nos referidos sistemas.</li>
            <li className="list-group-item">11.12. A Administradora pode disponibilizar as informações inseridas pelos Usuários assim que os Usuários deixarem de usar o Aplicativo de acordo com as disposições dos Termos e Condições e do PPI, desde que isso não resulte em violação dos direitos dos Usuários.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>12. Privacidade e Dados Pessoais</h2>
        <p>12.1. O Usuário, por meio deste, fornece à Administradora consentimento prévio, expresso e informado para quaisquer Dados Pessoais (conforme definido abaixo) que sejam coletados como consequência do uso do Aplicativo, para serem usados, processados, transferidos, transmitidos e/ou compartilhados em nível nacional e internacional, inclusive para terceiros localizados em países que podem não fornecer níveis adequados de proteção (“<span style={{textDecoration: 'underline'}}>Processamento</span>”). Para mitigar os riscos, tomamos medidas como análise de normas de privacidade e segurança de terceiros e/ou execução de contratos adequados que incluam regulamentos específicos sobre o tratamento de Dados Pessoais.</p>
        <p>12.2 Os Dados Pessoais podem se referir às seguintes informações do Usuário, permitindo que sejam identificados e reconhecidos como indivíduo: dados de identificação (nome, carteira de identidade do cidadão), dados demográficos (idade, sexo), dados de contato (número de telefone, endereço de e-mail, endereço físico), dados médicos como histórico clínico, tratamento médico etc. (isso se aplica apenas ao Paciente), informações sobre o uso do Aplicativo, vinculadas aos Usuários, que permitam sua identificação, bem como detalhes pessoais mencionados em conversas via bate-papo (“<span style={{textDecoration: 'underline'}}>Dados Pessoais</span>”). </p>
        <p>12.3. O Processamento ocorrerá para as seguintes finalidades: (i) a troca de informações para prestação de Assistência; (ii) envio de alertas e notificações de dentro do Aplicativo; (iii) envio de alertas e mensagens por meio    e aplicativos de mensagens de terceiros, tais como, mas não se limitando a WhatsApp ou aplicativos similares ; (iv) envio e troca de mensagens instantâneas via bate-papo; (v) hospedagem do Aplicativo em servidores externos; (vi) Dados Pessoais consultados pela Administradora e/ou pelo Prestador de Cuidados, para fins das Funcionalidades e demais usos do Aplicativo para trabalhar, e (vii) a realização de atividades de suporte e manutenção pelo Operador da Plataforma (conforme definido abaixo).</p>
        <p>12.4. Os Dados Pessoais serão coletados dos diferentes Usuários que registram e carregam informações no Aplicativo.</p>
        <p>12.5. O Usuário como titular dos dados tem os seguintes direitos: (i) conhecer, atualizar, apagar e retificar Dados Pessoais; (ii) solicitar comprovação da autorização concedida à Administradora; (iii) mediante solicitação prévia, ser informado sobre o uso dos Dados Pessoais pela Administradora; (iv) apresentar reclamação em razão de violações ao regime de proteção de dados, perante autoridades competentes, e (v) retirar a autorização para processar e/ou solicitar o apagamento dos Dados Pessoais, na medida em que não haja obrigações legais ou contratuais de permanecer no banco de dados.</p>
        <p>12.6. Para saber mais sobre seus direitos, como exercer esses direitos e as finalidades e usos aos quais os Dados Pessoais estarão sujeitos, os Usuários podem consultar o PPI Operadora da Plataforma, que está disponível em <a href="https://www.myalivius.com/PrivacyPolicy.html">https://www.myalivius.com/PrivacyPolicy.html</a>. Qualquer consulta, solicitação, reivindicação, reclamação ou petição relacionada a Dados Pessoais pode ser enviada para <a href="mailto:dataprivacy@svaaswellness.com">dataprivacy@svaaswellness.com</a>.</p>
        <p>12.7. O Usuário declara, aceita e reconhece ter sido informado de que o consentimento para autorizar o tratamento de dados pessoais sensíveis é completamente opcional.</p>
        <p>12.8. O Paciente aceita e concede consentimento prévio, expresso e informado para o Cuidador relatar informações e cumprir e executar as funcionalidades em nome do Paciente.</p>
        <p>12.9. De acordo com as disposições da Cláusula 11.7, o Paciente e o Cuidador aceitam que a Administradora possa compartilhar as informações do Paciente e do Cuidador com terceiros.</p>
        <p>12.10. Ao utilizar nosso aplicativo, o Usuário concorda em receber notificações push através do WhatsApp ou através de qualquer outro provedor de serviços. O Usuário reconhece e concorda (i) em receber tais notificações, (ii) que as pessoais do Usuário também serão processadas pelo WhatsApp com base nos Termos e Condições de Uso do WhatsApp Business (iii) que tais notificações serão enviadas de acordo com os Termos e Condições do WhatsApp (iv) para cumprir tais termos e condições. O Usuário também poderá revogar seu consentimento escrevendo-nos em <a href="mailto:dataprivacy@svaaswellness.com">dataprivacy@svaaswellness.com</a>. O Usuário também reconhece que as informações pessoais serão coletadas, processadas ou transferidas e/ou em nível nacional ou internacional para tais serviços.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>13. Operador de Plataforma</h2>
        <p>A Svaas Wellness Limited (“Operadora da Plataforma”) é a proprietária e operadora do Aplicativo e visa fornecer soluções digitais de saúde que levem a uma melhor qualidade de vida e melhores resultados de saúde no interesse de diversos grupos de consumidores e para diferentes geografias. Nesse contexto, o Operador da Plataforma exige Dados Pessoais para criar e fornecer benefícios de saúde aos Usuários e otimizem as Funcionalidades do Aplicativo, de acordo com a Política de Privacidade do Aplicativo</p>
        <p>O Operador da Plataforma e qualquer outra empresa pertencente ou controlada pelo Operador da Plataforma (“Afiliadas”) são os titulares dos direitos de propriedade intelectual no e para o Aplicativo. Os poderes, direitos e obrigações contidos nas Cláusulas 8, 9, 11, 15, 16, 17 e 18 destes Termos e Condições são entendidos como sendo acordados em favor do Operador da Plataforma e suas Afiliadas, que está habilitado a fazer cumprir o cumprimento dos Usuários diretamente.</p>
        <p>Os Usuários e a Administradora concordam que o Operador da Plataforma e suas Afiliadas não serão responsáveis pelas Funções e Conteúdos do Aplicativo.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>14. Dados Tratados pelo Operador da Plataforma</h2>
        <p>14.1 Para conhecer os direitos a que tem direito, as formas de execução e as finalidades e utilizações a que os Dados Pessoais estarão sujeitos, os Usuários poderão consultar a Política de Tratamento de Informações do Operador da Plataforma, disponível em <a href="https://www.myalivius.com/PrivacyPolicy.html">https://www.myalivius.com/PrivacyPolicy.html</a>. Qualquer consulta, solicitação, reivindicação, reclamação ou petição relacionada a Dados Pessoais tratados pelo Operador da Plataforma poderá ser enviada a <a href="mailto:dataprivacy@svaaswellness.com">[dataprivacy@svaaswellness.com]</a>.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>15. Limitação de Responsabilidade e Não Declaração e Garantias</h2>
        <ul className="list-group">
            <li className="list-group-item">15.1. A Administradora não é responsável pelo uso do Aplicativo pelo Usuário. A Administradora não assume responsabilidade no caso de a Assistência implicar ou envolver, direta ou indiretamente, qualquer atividade que preveja a prestação de serviços de saúde ou atividades que sejam restritas ou sujeitas a regulamentação específica. Isso inclui, sem limitação, diagnóstico, prescrição para o uso de medicamentos ou farmacovigilância, telemedicina e tratamento de tele-orientação através do Aplicativo.</li>
            <li className="list-group-item">15.2. Os Conteúdos, software, Funções e outras informações publicadas no Aplicativo podem conter erros ou imprecisões. A Administradora não garante a exatidão dos Conteúdos, software, Funções ou outras informações publicadas no Aplicativo e não assume qualquer responsabilidade por imprecisões ou erros a este respeito.</li>
            <li className="list-group-item">15.3. Por nenhuma razão e em nenhuma hipótese a Administradora (seus conselheiros, diretores e filiais) será responsável por danos diretos, indiretos, punitivos, incidentais, especiais, consequenciais, compensatórios ou qualquer outro tipo de dano derivado ou relacionado ao login ou uso pelo Usuário do Aplicativo, independentemente da teoria de responsabilidade que embasa as alegações.</li>
            <li className="list-group-item">15.4. A Administradora não assume qualquer tipo de responsabilidade nem garante de forma alguma a qualidade, validade, eficácia e veracidade dos Conteúdos nem das Funções do Aplicativo.</li>
            <li className="list-group-item">15.5. Os limites e isenções de responsabilidade aqui contidos nestes Termos e Condições continuarão e serão aplicáveis no caso de ser determinado que qualquer uma das disposições em particular não cumpre com seu propósito essencial. Os limites e isenções de responsabilidade são estabelecidos em favor da Administradora, sem prejuízo do disposto na Cláusula 19 destes Termos e Condições.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>16. Indenização</h2>
        <p>16.1 O acesso e uso do Aplicativo significa que o Usuário aceitou manter a Administradora livre de danos por ou em relação a qualquer reivindicação, reclamação, investigação administrativa ou legal, ação legal ou responsabilidade comprovada com base ou relacionada à violação destes Termos e Condições e/ou ao uso do Aplicativo. Como resultado, o Usuário não pode apresentar quaisquer reivindicações ou ações de qualquer tipo, nem recuperar nem tentar recuperar qualquer tipo de compensação por danos ou prejuízos de qualquer tipo pela Administradora como resultado de qualquer decisão ou ação tomada pela Administradora na administração, manuseio, operação e execução do Aplicativo.</p>
        <p>16.2 O Usuário deve defender, compensar e manter de danos a Administradora, suas Afiliadas e cada um de seus funcionários, o Operador da Plataforma, suas Afiliadas e cada um de seus funcionários, contratados, diretores, provedores e representantes de todas as responsabilidades, reivindicações e despesas, incluindo todos os custos relacionados, isso inclui honorários advocatícios que surjam ou estejam relacionados ao uso ou uso indevido ou acesso ao Aplicativo, ou qualquer outro uso das informações inseridas no Aplicativo, pelo Usuário, ou a violação dos Termos e Condições ou violação pelo Usuário ou qualquer terceiro que use sua conta, de qualquer propriedade intelectual ou outro direito de qualquer pessoa ou entidade. A Administradora reserva-se o direito de assumir a defesa e o controle exclusivos de qualquer assunto sujeito a compensação pelo Usuário. Nesse caso, o Usuário ajudará e cooperará com a Administradora em sua defesa.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>17. Rescisão</h2>
        <ul className="list-group">
            <li className="list-group-item">17.1. Estes Termos e Condições, com suas modificações, alterações e exclusões, tornamse vinculantes para o Usuário quando o Usuário baixar ou se registrar para o Aplicativo e continuarão em vigor até que sejam rescindidos pelo Usuário ou pela Administradora, de acordo com os termos aqui contidos. Não obstante o acima exposto, as restrições, obrigações e riscos assumidos pelo Usuário após a aceitação dos Termos e Condições, bem como as limitações de responsabilidade e compensações estabelecidas pela Administradora nestes Termos e Condições sobreviverão à rescisão dos Termos e Condições.</li>
            <li className="list-group-item">17.2. A Administradora reserva-se o direito de remover o Aplicativo e/ou rescindir os Termos e Condições, total ou parcialmente, a seu exclusivo critério. O encerramento pode resultar na destruição total de todas as informações relacionadas à conta do Usuário. Consulte a Política de Privacidade para compreender as condições de retenção dos seus dados após o encerramento da sua conta.</li>
            <li className="list-group-item">17.3. Qualquer tipo de responsabilidade que os Usuários possam atribuir à Administradora como resultado da rescisão destes Termos e Condições e do Aplicativo foi expressamente excluído.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>18. Direitos de Propriedade e Direitos de Propriedade Intelectual da Administradora e de Terceiros</h2>
        <ul className="list-group">
            <li className="list-group-item">18.1. Todos os elementos e informações deste Aplicativo e das suas Funções, incluindo texto, processos, métodos, segredos de empresa, know-how, técnicas, formas, imagens, música, marcas, logotipos, emblemas, nomes comerciais, sons, gráficos, vídeos, animação e outros materiais e ferramentas sujeitos a proteção através de propriedade intelectual são e continuarão a ser propriedade do seu respetivo proprietário, seja a Administradora, o Operador da Plataforma, titulares de licenças, beneficiários e, em geral, quaisquer terceiros.</li>
            <li className="list-group-item">18.2. Qualquer uso não autorizado dos elementos e informações deste Aplicativo que viole a propriedade e os direitos de propriedade intelectual da Administradora, do Operador da Plataforma, de seus contratados, titulares de licenças, beneficiários e, em geral, de terceiros, pode implicar o início de ações legais correspondentes pelos titulares de tais direitos.</li>
            <li className="list-group-item">18.3. O acesso e o uso do Aplicativo não implicarão, de forma alguma, a transferência ou recusa de qualquer licença, concessão ou direito de uso de quaisquer textos, processos, métodos, segredos de empresa, know-how, técnicas, formas, imagens, músicas, marcas, logotipos, emblemas, nomes comerciais, sons, gráficos, vídeos, animações e outros materiais e ferramentas sujeitos à proteção por meio de direitos de propriedade intelectual, diferentes das disposições declaradas precisamente neste documento.</li>
            <li className="list-group-item">18.4. É proibida a criação de páginas da web, gateways da Internet, aplicativos, documentos comerciais, eletrônicos ou programas de computador ou aplicativos de computação de qualquer tipo que contenham hiperlinks ou marcas que redirecionem o navegador para qualquer parte do Aplicativo.</li>
            <li className="list-group-item">18.5. Fica expressamente excluído qualquer tipo de responsabilidade que o Usuário possa atribuir à Administradora em decorrência da violação dos direitos de outros usuários ou de terceiros à sua propriedade e direitos de propriedade intelectual.</li>
            <li className="list-group-item">18.6. A Administradora e o Operador da Plataforma não assumem qualquer responsabilidade por questões de propriedade intelectual derivadas do conteúdo dos Usuários ou de terceiros e das informações fornecidas pelos Usuários ou por terceiros em seus produtos ou serviços.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>19. Violação dos Termos e Condições</h2>
        <ul className="list-group">
            <li className="list-group-item">19.1. Qualquer violação dos Termos e Condições ou qualquer reclamação ou informação que a Administradora receba em relação à violação, abuso ou uso inadequado destes Termos e Condições, do Aplicativo e/ou de suas Funções poderá ser investigada pela Administradora ou por terceiros, devendo ser tomadas as medidas necessárias para iniciar todas as ações legais e extrajudiciais contra os Usuários. Isto é, entre outras razões, para encerrar a conduta e obter os reparos e indenizações aos quais há recurso de acordo com as leis aplicáveis. A violação destes Termos e Condições pode resultar na suspensão ou cancelamento definitivo de sua conta e na correspondente responsabilidade civil ou criminal.</li>
            <li className="list-group-item">19.2. Se o Usuário questionar a legalidade de suas ações durante suas atividades no Aplicativo e/ou o uso de suas Funções, não hesite em entrar em contato conosco com antecedência.</li>
            <li className="list-group-item">19.3. Se o Usuário considerar que seus direitos foram violados por terceiros ou pela Administradora, ou que sua conta foi suspensa ou cancelada por engano ou porque sua identidade foi confundida, não hesite em contatar-nos.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>20. Sites e/ou Aplicativos de Terceiros</h2>
        <ul className="list-group">
            <li className="list-group-item">20.1. Os sites vinculados ao Aplicativo não são de propriedade da Administradora nem são operados, controlados ou administrados pela Administradora e, portanto, a Administradora está isenta de qualquer tipo de responsabilidade quanto ao conteúdo, práticas, políticas, segurança, serviços, disponibilidade, cumprimento de termos e condições de uso ou políticas de privacidade.</li>
            <li className="list-group-item">20.2. Qualquer link feito no Aplicativo para sites de terceiros não constitui patrocínio, proteção, defesa, garantia ou suporte de qualquer tipo da Administradora em relação ao seu conteúdo, práticas, políticas, segurança, serviços, disponibilidade e conformidade com termos e condições de uso ou políticas de privacidade.</li>
            <li className="list-group-item">20.3. O Usuário é responsável pelo acesso e uso de sites de terceiros que estejam vinculados ao Aplicativo e/ou às Funções.</li>
            <li className="list-group-item">20.4. Fica expressamente excluído qualquer tipo de responsabilidade que os Usuários possam atribuir à Administradora em decorrência de danos relacionados ao uso de gateways, serviços, produtos ou conteúdos que estejam disponíveis por meio de sites de terceiros vinculados ao Aplicativo.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>21. Relação entre a Administradora e o Usuário</h2>
        <ul className="list-group">
            <li className="list-group-item">21.1. A Administradora não possui qualquer tipo de relação jurídica com o Usuário diferente daquela que surge ao longo destes Termos e Condições. Portanto, a Administradora não é obrigada a adotar medidas em relação ao seguinte: 
                <ul>
                    <li>Usuários que podem acessar o Aplicativo e seu uso de suas Funções.</li>
                    <li>A qualidade, validade, eficácia e veracidade dos Conteúdos oferecidos no Aplicativo. </li>
                    <li>Os efeitos que certos tipos de Conteúdo podem ter sobre os Usuários.</li>
                    <li>A interpretação que os Usuários fazem do Conteúdo e/ou Funções.</li>
                </ul>
                </li>
            <li className="list-group-item">21.2. A Administradora não garante a autenticidade ou veracidade das informações fornecidas no Aplicativo por Pacientes e Cuidadores em relação à qualidade, validade, eficácia e veracidade dos Conteúdos oferecidos.</li>
        </ul>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>22. Lei Aplicável</h2>
        <p>Estes Termos e Condições serão regidos e interpretados de acordo com as leis da República Federativa do Brasil.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>23. Independência das Cláusulas</h2>
        <p>Se qualquer um dos termos, disposições ou condições destes Termos e Condições ou a sua aplicação a qualquer circunstância for considerada inválida ou inexequível, a validade ou exequibilidade do restante destes Termos e Condições não será afetada e cada um dos outros termos, disposições e condições destes Termos e Condições será válido e exequível em toda a medida permitida por lei.</p>

        <h2 className="mt-4" style={{textDecoration: 'underline'}}>24. Contato</h2>
        <p>Se os Usuários tiverem alguma dúvida ou preocupação sobre os Termos e Condições, eles podem entrar em contato com a Operadora da Plataforma por e-mail: <a href="mailto:dataprivacy@svaaswellness.com">dataprivacy@svaaswellness.com</a>.</p>
    </div>
    )
}

export default Terms